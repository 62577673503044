<template>
  <div class="big">
    <div class="header">
      <div class="header-left fl" id="time"></div>
      <div class="header-center fl">
        <div class="header-title">异常数据监测平台</div>
        <div class="header-img"></div>
      </div>
      <div class="header-right fl"></div>
      <div class="header-bottom fl"></div>
    </div>
    <!-- <div class="title"></div> -->
    <!-- <el-divider></el-divider> -->
    <div class="box">
      <div class="left">
        <el-card class="box-card add">
          <div slot="header" class="clearfix">
            <span
              ><span style="color: #13e0ff; font-size: 20px">设备 </span>
              管理</span
            >
          </div>
          <el-table
            :data="sbData"
            style="width: 100%"
            :row-class-name="tableRowClassName"
          >
            <el-table-column prop="hostname" label="主机名"></el-table-column>
            <el-table-column prop="cpu_usage" label="CPU使用率" width="100">
            </el-table-column>
            <el-table-column prop="load_status" label="负载">
              <template slot-scope="scope">
                <span
                  v-if="scope.row.load_status > 20"
                  style="color: rgb(255 0 0)"
                  >{{ scope.row.load_status }}</span
                >
                <span
                  v-else-if="scope.row.load_status > 5"
                  style="color: rgb(255 166 4)"
                  >{{ scope.row.load_status }}</span
                >
                <span v-else style="color: rgb(103, 194, 58)">{{
                  scope.row.load_status
                }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="ram_percent" label="内存使用率" width="100">
            </el-table-column>
          </el-table>
        </el-card>
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span
              ><span style="color: #13e0ff; font-size: 20px">服务 </span>
              管理</span
            >
            <!-- <el-button style="float: right; padding: 3px 0" type="text"
              >操作按钮</el-button
            > -->
          </div>
          <el-table
            :data="fwData"
            style="width: 100%"
            :row-class-name="tableRowClassName"
          >
            <el-table-column prop="title" label="名称"> </el-table-column>
            <el-table-column prop="host" label="域名">
              <template slot-scope="scope">
                <span v-for="(item, index) in scope.row.host" :key="index">
                  {{ item.name }}
                  <span
                    :style="
                      item.state == '正常'
                        ? 'color:rgb(103, 194, 58)'
                        : 'color:red'
                    "
                  >
                    【{{ item.state }} 】
                  </span>
                  <br />
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="device_title" label="所在设备">
              <template slot-scope="scope">
                {{ scope.row.device_hostname }} - {{ scope.row.device_title }} -
                {{ scope.row.device_type }}
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </div>
      <div class="right">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span
              ><span style="color: #13e0ff; font-size: 20px">单位 </span>
              管理</span
            >
          </div>
          <el-table
            :data="dWData"
            style="width: 100%"
            :row-class-name="tableRowClassName"
          >
            <el-table-column prop="title" label="名称"> </el-table-column>
            <el-table-column prop="businessday" label="商业授权剩余（天）">
              <template slot-scope="scope">
                <span
                  v-if="
                    scope.row.businessday > 7 && scope.row.businessday <= 30
                  "
                  style="color: yellow"
                  >{{ scope.row.businessday }}</span
                >
                <span
                  v-else-if="scope.row.businessday <= 7"
                  style="color: red"
                  >{{ scope.row.businessday }}</span
                >
                <span v-else>{{ scope.row.businessday }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="tryday" label="试用授权剩余（天）">
              <template slot-scope="scope">
                <span
                  v-if="scope.row.tryday > 7 && scope.row.tryday <= 30"
                  style="color: yellow"
                  >{{ scope.row.tryday }}</span
                >
                <span v-else-if="scope.row.tryday <= 7" style="color: red">{{
                  scope.row.tryday
                }}</span>
                <span v-else>{{ scope.row.tryday }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="serviceday" label="服务剩余（天）">
              <template slot-scope="scope">
                <span
                  v-if="scope.row.serviceday > 7 && scope.row.serviceday <= 30"
                  style="color: yellow"
                  >{{ scope.row.serviceday }}</span
                >
                <span
                  v-else-if="scope.row.serviceday <= 7"
                  style="color: red"
                  >{{ scope.row.serviceday }}</span
                >
                <span v-else>{{ scope.row.serviceday }}</span>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
        <el-card class="box-card add" :body-style="{ height: '500px' }">
          <div slot="header" class="clearfix">
            <span
              >交换机管理
              <span style="color: #13e0ff; font-size: 20px">监控 </span></span
            >
          </div>
          <div style="width: 100%; height: 100%">
            <iframe
              style="width: 100%; height: 100%"
              src="https://cacti.educg.net/mrtg/"
            ></iframe>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      sbData: [],
      fWData: [],
      dWData: [],
      JkData: [],
    };
  },
  mounted() {
    // 设备管理
    this.sbInit();
    // 服务管理
    this.fwInit();
    // 单位管理
    this.dwInit();
    // 监控管理
    this.jkInit();
    setInterval(() => {
      // 设备管理
      this.sbInit();
      // 服务管理
      this.fwInit();
      // 单位管理
      this.dwInit();
      // 监控管理
      this.jkInit();
    }, 60000);
  },
  methods: {
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex === 1) {
        return "warning-row";
      } else if (rowIndex === 3) {
        return "success-row";
      }
      return "";
    },
    // 设备管理
    sbInit() {
      let data = {
        op: "search",
        value: "",
        type: "",
        page: 1,
        limit: 10,
        location: "",
        company: "",
        hostdev: "",
        searchlabeltype: "",
        searchlabel: "",
      };
      let _this = this;
      this.$ajax
        .post(this.device, _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.sbData = response.data.data.data;
            // _this.fzInit(response.data.data.data);
          } else if (response.data.value == "sessionerror") {
            _this.$message.error(response.data.msg);
            setTimeout(() => {
              _this.$router.push({ path: "/" });
            }, 2500);
          } else {
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 服务管理
    fwInit() {
      let data = {
        op: "search",
        title: "",
        //   state: this.stateTop,
        //   mycreate: this.mycreate,

        page: 1,
        limit: 10,
      };
      let _this = this;
      this.$ajax
        .post(this.server, _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.fwData = response.data.data.data;
          } else {
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 单位管理
    dwInit() {
      let data = {
        op: "search",
        title: "",
        page: 1,
        limit: 10,
        sort_k: "",
        sort_v: "",
      };
      let _this = this;
      this.$ajax
        .post(this.company,_this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.dWData = response.data.data.data;
          } else {
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 监控管理
    jkInit() {},
  },
};
</script>
<style scoped>
.header {
  width: 100%;
  height: 1.5rem;
  /* background: red; */
}
.header .header-left {
  width: 25%;
  height: 1.05rem;
  color: white;
  /* border: 1px solid green; */
  text-align: center;
  line-height: 1.05rem;
}
.header .header-center {
  width: 50%;
  height: 98px;
  /* border: 1px solid red; */
  position: relative;
  padding-top: 20px;
}
.header .header-center .header-title {
  text-align: center;
  color: #ffffff;
  font-size: 20px;
  text-shadow: 0 0 0.3rem #00d8ff;
  line-height: 1.05rem;
}
.header .header-img {
  background: url(../assets/head.gif) no-repeat center center;
  background-size: 100%;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0.4rem;
}
.header .header-right {
  width: 25%;
  height: 1.05rem;
  /* border: 1px solid gold; */
}
.header .header-bottom {
  width: calc(100% - 0.4rem);
  height: 0.16rem;
  background: url(../assets/header.png) no-repeat;
  background-size: 100%;
  /* padding: 0 0.2rem; */
  margin-left: 0.2rem;
}
.fl {
  float: left;
}
.big {
  color: #fff;
  background: #2e2d2d
}
.title {
  font-size: 20px;
  padding-top: 20px;
  margin-bottom: 10px;
  font-weight: bold;
  color: #00e8f8;
}
.box {
  display: flex;
  justify-content: space-between;
  text-align: left;
  width: 100%;
  padding-top:10px;
}
.left {
  width: 50%;
}
.right {
  width: 49%;
}

.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
.el-card {
  margin-bottom: 10px;
}
.el-card.add {
  border: 1px solid #4e9690 !important;
}
</style>
<style>
.big .el-card__header {
  padding: 8px 20px !important;
}
</style>